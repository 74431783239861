.hover-buttons {
  position: absolute;
  bottom: 10px;
  left: 65%;
  transform: translateX(-50%);
  background: none;
  z-index: 10;
  /*  */
}
.photoToolTipCard {
  min-height: 150px;
  position: relative;
}

.photoToolTipCard:hover {
  background: rgba(0, 0, 0, 0.1);
}
.avatarImg {
  object-fit: 'contain important';
  cursor: 'pointer';
}
.photoImg {
  width: '16px important';
  height: '16px important';
  margin: '2px important';
  /* background-color: PF_WHITE; */
}

.photoCard:hover {
  background: rgba(0, 0, 0, 0.1);
}
