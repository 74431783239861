@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Open+Sans:wght@600&family=Lato:wght@400&display=swap');

:root {
  --primary-color: #0074d9;
  --blue-500: #0074d9 !important;
  --blue-200: #eff6ff;
  --primary-500: #0074d9 !important;
  --heading-color: #21272a;
  --lightgrey: #fafafa;
  --black: #000000;
  --blue-100: #d0e1fd;
  --mint-grey: #6b7280;
  --transparent-5: rgba(0, 0, 0, 0.54);
  --checkbox: #06b6d4;
  --pink-shade-1: #c76daf;
  --black-transparent-3: rgba(0, 0, 0, 0.3);
  --black-transparent-1: rgba(0, 0, 0, 0.1);
  --black-transparent-08: rgba(0, 0, 0, 0.08);
  --black-transparent-02: rgba(0, 0, 0, 0.2);
  --back-shade-1: #333;
  --back-shade-2: #444;
  --back-shade-3: #555;
  --grey-shade-1: #f3f3f3;
  --white-shade-75: rgba(255, 255, 255, 0.75);
  --grey-shade-2: #dfdfdf;
  --grey-shade-3: #d3d3d3;
  --pf-white: #ffffff;
}

@import url('./MUIStyle.css');

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('../theme/fonts/Inter-Regular.ttf') format('woff2');
}
@font-face {
  font-family: 'Inter bold';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('../theme/fonts/Inter-Bold.ttf') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('../theme/fonts/Inter-Regular.ttf') format('woff2');
}
body,
input {
  font-family: 'Inter var' !important;
}
strong,
h1,
h2,
h3,
h4,
.font-bold {
  font-family: 'Inter bold';
  color: var(--heading-color);
}
.text-primary {
  color: var(--primary-color);
}
.light-grey-bg-100 {
  background-color: var(--lightgrey);
}
.p-sidebar-header h2 {
  color: #ffffff !important;
}

.p-badge {
  font-size: 10px;
  /* border-radius: 6px; */
}
header button.p-button {
  background-color: #0062b8;
  border: none;
}
.p-component.p-button-sm.p-button-rounded {
  height: 40px;
  width: 40px;
}

/* Other Css */
ul#pr_id_1_list {
  padding: 0px;
}

.p-button-primary {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}

/* Tab custom Css */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #004e93;
  color: #ffffff;
  background-color: #004e93;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-tabview .p-tabview-nav {
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: #eff6ff;
  font-size: 14px;
  color: #609af8;
}
.p-tabview .p-tabview-selected {
  font-family: 'Inter bold';
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  border-color: #e5e7eb;
}
/* Tab custom Css End */

/* Table Custom CSS */
.p-datatable .p-datatable-tbody > tr > td,
.p-treetable-table .p-treetable-tbody > tr > td {
  padding: 14px;
  color: #495057;
  font-size: 14px;
}
/* Table Custom CSS End */

.p-button.p-button-danger.p-button-outlined,
.p-button.p-button-warning.p-button-outlined,
.p-button.p-button-success.p-button-outlined,
.p-button.p-button-info.p-button-outlined,
.p-button.p-button-secondary.p-button-outlined,
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.p-button.p-button-warning.p-button-outlined {
  color: #ea580c;
}
.p-button.p-button-success.p-button-outlined {
  color: #22c55e;
}
.p-button.p-button-info.p-button-outlined {
  color: var(--primary-color);
}
.p-button.p-button-secondary.p-button-outlined {
  color: #64748b;
}
.p-button.p-button-success.p-button-outlined,
.p-button.p-button-text:not(:disabled):hover {
  color: #22c55e !important;
}
.p-button.p-button-text,
.p-button.p-button-text:not(:disabled):hover,
.p-button.p-button-text:not(:disabled):active,
.p-button .p-badge,
.p-splitbutton.p-button-outlined > .p-button,
.p-splitbutton.p-button-outlined > .p-button:not(:disabled):hover,
.p-splitbutton.p-button-outlined > .p-button:not(:disabled):active {
  color: var(--primary-color) !important;
}
.p-button.p-button-danger.p-button-outlined,
.p-button.p-button-danger.p-button-outlined:hover {
  color: #ef4444 !important;
}
/* Danger Button */
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
  color: #ffffff;
  background: #ef4444;
  border: 1px solid #ef4444;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid var(--primary-color);
}

.p-overlaypanel .p-overlaypanel-close,
.p-tag.p-tag-primary {
  background: var(--primary-color);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
/* .p-inline-message.p-inline-message-info,
.p-message.p-message-info {
  border: solid var(--primary-color);
  color: var(--primary-color);
} */

.p-splitbutton.p-button-text > .p-button,
.p-splitbutton.p-button-text > .p-button:not(:disabled):hover,
.p-splitbutton.p-button-text > .p-button:not(:disabled):active,
.p-tabview .p-tabview-nav-btn.p-link,
.p-inline-message.p-inline-message-info .p-inline-message-icon,
.p-message.p-message-info .p-message-icon,
.p-message.p-message-info .p-message-close,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--primary-color);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--primary-color);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--primary-color);
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover,
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-cascadeselect:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-cascadeselect:not(.p-disabled).p-focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-chips .p-chips-multiple-container:not(.p-disabled):hover,
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus,
.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-treeselect:not(.p-disabled).p-focus,
.p-treeselect:not(.p-disabled):hover {
  border-color: var(--primary-color);
}
.p-checkbox .p-checkbox-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-selectbutton .p-button.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-datatable .p-column-resizer-helper,
.p-treetable .p-column-resizer-helper,
.p-steps .p-steps-item.p-highlight .p-steps-number,
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight,
.p-calendar button.p-datepicker-trigger {
  background: var(--primary-color);
}

.p-togglebutton.p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

/* New color classes */
.blue-text-100 {
  color: var(--primary-100);
}
.blue-bg-100 {
  background-color: var(--primary-100);
}
.text-color-900 {
  color: #212121;
}

/* Custom Css */
.p-calendar .p-button.p-button-icon-only {
  padding-top: 0.55rem !important;
  padding-bottom: 0.55rem !important;
}
.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #d1d5db;
}
label {
  color: #495057;
}
.p-inputtext,
label,
.p-datepicker .p-timepicker span {
  font-size: 12px;
}
.p-inputtext-sm,
.height-40 {
  height: 40px;
}
.width-18 {
  width: 18px;
}
.height-18 {
  height: 18px;
}
.p-float-label input:focus ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label,
.p-float-label .p-tooltip-target-wrapper ~ label {
  top: 0.2rem;
  background-color: #ffffff;
  font-size: 12px !important;
  padding: 0 2px;
}
.p-datatable .p-datatable-thead > tr > th,
.p-treetable-table .p-treetable-thead > tr > th {
  background-color: #eff6ff !important;
  padding: 14px !important;
  font-family: 'Inter bold';
  font-size: 14px;
  white-space: nowrap !important;
}
.p-datatable .p-datatable-thead > tr > th .p-column-title {
  color: #343a40 !important;
}
.p-datatable .p-column-header-content span[data-pc-section='sort'],
.p-datatable
  .p-highlight
  .p-column-header-content
  span[data-pc-section='sort'] {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.p-datatable .p-column-header-content span[data-pc-section='sort'] {
  margin-left: 7px;
}
.p-datatable
  .p-highlight
  .p-column-header-content
  span[data-pc-section='sort'] {
  background-color: #d0e1fd;
  border-radius: 15px;
  font-size: 5px;
}
.p-datatable .p-column-header-content span i {
  color: var(--blue-500);
}
.p-datatable .p-column-header-content span[data-pc-section='sort'] svg {
  margin: 0;
}
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-column-filter-menu-button {
  color: var(--primary-color);
  margin-left: 15px;
}
.p-column-filter-overlay .p-dropdown-label {
  padding: 0.563rem 0.75rem;
}
.p-disabled,
.p-component:disabled {
  opacity: 0.6 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #d0e1fd !important;
}
.p-sidebar-header h2 {
  color: #ffffff !important;
}
.minheight-250 {
  min-height: 250px;
}
.calendar-container .fc-button-group .fc-button-primary {
  background-color: #0074d9 !important;
  border-color: #0074d9 !important;
  opacity: 0.6;
}
.calendar-container .fc-button-group .fc-button-primary.fc-button-active {
  opacity: 1;
}
.p-paginator.p-component {
  justify-content: right;
}
.fc-header-toolbar .fc-button-primary {
  opacity: 1;
}
.p-float-label input.p-filled ~ label {
  top: 0.2rem !important;
}
.pie-chart {
  min-height: 140px;
  max-height: 140px;
  max-width: 140px;
  min-width: 140px;
  height: 140px;
  width: 140px;
}
.user-detail-form .p-tabview-nav li:nth-last-child(2) {
  padding-right: 40px;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  max-width: 30rem;
  text-wrap: wrap;
}

.gray-bg-300 {
  background-color: rgb(112 112 112 / 40%) !important;
}

.tableTitleBold,
.fc-datagrid-header th,
.fc-timeline-slot-frame {
  font-family: 'Inter bold';
}
.p-datatable {
  border-left: 1px solid var(--surface-200);
  border-right: 1px solid var(--surface-200);
}
.gray-bg-300 {
  background-color: rgb(112 112 112 / 40%) !important;
}
.side-menu-list .custom-panel-menu a {
  padding: 15px 0;
  display: flex;
  text-decoration: none;
  color: #6b7280;
}
.side-menu-list .menu-level-one .p-panelmenu-header {
  padding-bottom: 15px !important;
}
.side-menu-list .p-panelmenu .active {
  color: var(--primary-color);
}
.side-menu-list .p-menuitem-text a {
  position: relative;
  left: -28px;
  width: calc(100% + 28px);
  padding-left: 28px;
}
.bg-transparent {
  background-color: transparent !important;
  background: transparent !important;
}
.labor-items .p-accordion-header.p-highlight {
  background-color: #eff6ff;
}
.labor-items .p-datatable .p-datatable-thead > tr > th {
  background-color: #f8f9fa !important;
}
.word-break {
  word-break: break-word;
}
.text-break-anywhere {
  overflow-wrap: anywhere;
}
.p-menu.p-menu-overlay {
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}
table.p-datatable-table .p-column-filter.p-column-filter-menu,
table.p-datatable-table
  .p-column-filter.p-column-filter-menu
  button.p-column-filter-menu-button {
  margin-left: 0;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-8 {
  font-size: 8px;
}
.object-fit-cover {
  object-fit: cover;
}
.p-toast.toast-index {
  z-index: 9999999999 !important;
}
.inventory-container .p-sidebar-mask {
  z-index: 9999999 !important;
}

/* Prime React version update: UI bug fix changes css */
.side-menu-list .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
  border: 1px solid #ffffff !important;
}
.p-input-icon-left > .p-input-prefix,
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type {
  color: #6b7280 !important;
  left: 0.75rem !important;
}
.p-input-icon-right > .p-input-suffix,
.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type {
  color: #6b7280 !important;
  right: 0.75rem !important;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

.height-800 {
  height: 800px;
}
.minWidth-970 {
  min-width: 970px;
}
.light-blue-100 {
  background-color: #f0f3f8;
}
.z-index-999 {
  z-index: 999 !important;
}
.w-36 {
  width: 40px !important;
}
.PFCalendar-lg input {
  height: 3rem !important;
}
.schedular-map div.MuiTabs-root {
  border-bottom: none;
}
.schedular-map .MuiTabPanel-root {
  padding: 10px 12px;
}
.min-h-32 {
  min-height: 32px !important;
}
.min-h-367 {
  min-height: 367px !important;
}
.min-h-240 {
  min-height: 240px !important;
}
.text-overflow-hidden {
  overflow: hidden;
}
.text-decoration {
  text-decoration: none !important;
}
.rts-table table td,
.rts-table table td div {
  font-family: 'Inter var' !important;
}
.rts-table .MuiTablePagination-caption,
.rts-table .MuiTablePagination-select {
  color: var(--mint-grey);
  font-size: 14px;
}
.rts-table .MuiTablePagination-input .MuiSelect-icon {
  top: calc(50% - 9px);
  right: 6px;
}
.rts-table .MuiTablePagination-input {
  border: 1px solid var(--grey-shade-2);
  border-radius: 5px;
  padding: 4px;
}
.rts-table .MuiToolbar-gutters {
  background-color: var(--bg-blue-50);
}
.rts-table .MUIDataTableFooter-root-318 {
  background-color: var(--surface-50) !important;
}
.rts-table .MuiTableCell-footer {
  padding-right: 0 !important;
}
.rts-table .MUIDataTableFooter-root-275 {
  background-color: #fafafa !important;
}
.calendar-no-bg button.MuiButtonBase-root.Mui-disabled {
  background-color: transparent !important;
  border: none !important;
}
.transparent-5 {
  background-color: var(--transparent-05) !important;
}
.MuiCheckbox-root .MuiIconButton-label .MuiSvgIcon-root {
  fill: var(--checkbox) !important;
  border-color: var(--checkbox) !important;
  width: 20px;
  height: 20px;
}
.fc-header-toolbar .fc-resourceTimelineDay-button,
.fc-header-toolbar .fc-resourceTimelineWeek-button,
.fc-header-toolbar .fc-resourceTimelineMonth-button {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.inventory-body-row-height {
  height: 58px !important;
}
.inventory-thead-height {
  height: 60px !important;
}
.margin-top-110 {
  margin-top: 110px !important;
}

.fc-header-toolbar button {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.fc-datagrid-cell-frame {
  background-color: var(--grey-shade-1);
}

.font-family-lato {
  font-family: 'Lato', sans-serif;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 152px;
}

.child-container {
  flex: 1;
  min-width: 150px;
  box-sizing: border-box;
  flex: 1 1 calc(33.33% - 10px);
  max-width: 250px;
  min-width: 150px;
  box-sizing: border-box;
  text-align: center;
  min-width: 150px;
  max-width: 100%;
}

.installerbio-tooltip {
  width: 200px;
  max-height: 65px;
  background-color: #4b5563;
  border-radius: 5px;
  padding: 10px;
  font-size: 13px;
  display: none;
  top: 20px;
  left: 0px;
  z-index: 99999;
  transition: all 0.3s ease;
  min-height: 35px;
  font-family: 'Inter';
  line-height: 1.2;
  overflow-x: hidden;
  word-break: break-word;
  overflow-y: auto;
  white-space: break-spaces;
  box-shadow: 0px 1px 12px #8c8c8c;
}

.scrollable-container:hover .installerbio-tooltip {
  display: block;
}

.installerbio-tooltip:hover {
  overflow: auto;
}

.installerbio-tooltip::-webkit-scrollbar {
  width: 5px;
}

.installerbio-tooltip::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.installerbio-tooltip::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.installerbio-tooltip::-webkit-scrollbar-track {
  background: transparent;
}

.installerbio-tooltip {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.description {
  line-height: 1.4;
  white-space: normal;
  padding-right: 10px;
}
.calendar-page-height .fc-media-screen {
  height: calc(100vh - 410px) !important;
  min-height: 700px;
}

.scheduler-page-height .fc-media-screen {
  height: calc(100vh - 335px) !important;
  min-height: 700px;
}

.calendar-page-height .fc-media-screen {
  height: calc(100vh - 410px) !important;
  min-height: 700px;
}

.calendar-full-height .fc-media-screen,
.scheduler-full-height .fc-media-screen {
  height: calc(100vh - 900px) !important;
  min-height: 700px;
}

.calendar-fullScreen .fc-media-screen {
  height: calc(100vh - 200px) !important;
  min-height: 700px;
}
.calendar-component .fc-fullScreenButton-button {
  display: none;
}

.select-z {
  z-index: 99999 !important;
}
.css-online {
  margin-top: -5px !important;
  margin-left: -3px !important;
}

.p-confirm-popup-center {
  font-size: 13px !important;
  width: 256px !important;
  --overlayArrowLeft: 90px !important;
}
.p-confirm-popup .p-confirm-popup-footer button {
  padding: 7px !important;
  width: 56px !important;
}
.description-height {
  height: 24px !important;
}
.headersize {
  font-size: 24px !important;
}

/* contact */

.copy-icon {
  cursor: pointer;
  font-size: 1.1rem;
  transition:
    transform 0.2s,
    color 0.2s;
  color: #000;
}

.copy-icon:hover {
  transform: scale(1);
  color: #007bff;
}

.copy-icon.clicked {
  transform: scale(0.9);
  color: #28a745;
}

.copy-icon-customer-name {
  position: absolute;
  left: 95%;
}

.contact-tooltip {
  position: absolute;
  bottom: 30px;
  left: 90%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  opacity: 0.9;
  z-index: 10;
  white-space: nowrap;
}

.customer-name-tooltip {
  position: absolute;
  bottom: 30px;
  left: 90%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  opacity: 0.9;
  z-index: 10;
  white-space: nowrap;
}
.customer-email-tooltip {
  position: absolute;
  bottom: 18px;
  left: 87%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  opacity: 0.9;
  z-index: 10;
  white-space: nowrap;
}

.contact-button-w {
  width: 250px !important;
}

.alert-dialog {
  color: red;
  font-size: 10px;
}
.columnWidth {
  overflow: auto;
}
.columnWidth th {
  min-width: 200px;
}

.columnWidth th:first-child {
  min-width: 50px;
}

.columnWidth th:nth-child(4) {
  width: 100px;
}
.white-space {
  white-space: break-spaces;
}
.customer-login-logs input {
  min-width: 200px;
}

.customer-login-logs .p-card-body,
.customer-login-logs .p-card-content {
  padding: 3px;
}
.z-index-9999 {
  z-index: 9999 !important;
}
.min-w-300 {
  min-width: 300px !important;
}
.zsiq-float {
  bottom: 0 !important;
  height: 45px !important;
  right: 2px !important;
}
.e-schedule .e-resource-column-table > tbody > tr:nth-child(odd) td {
  background-color: var(--grey-shade-2);
}
.e-content-wrap > table > tbody > tr:nth-child(odd) td {
  background-color: var(--grey-shade-2);
}
.e-schedule-table
  tbody
  tr:nth-child(2)
  td:nth-child(2)
  .e-schedule-table
  tr
  td {
  border-bottom: 1px solid #969696 !important;
}
.assign-editicon-w {
  width: 30px !important;
}
.assign-accordian-w {
  width: 64px !important;
}
.check-time-w {
  width: 70px !important;
}
.custom-rating .p-rating-icon {
  width: 16px !important;
}

.custom-rating .p-rating {
  gap: 0.3rem !important;
}

.text-area-custom .p-inputtext:enabled:hover {
  border-color: #d1d5db;
}
/* Calendar Global CSS */
.p-datepicker table th {
  padding: 0;
}
.p-datepicker table td {
  padding: 0;
  font-size: 12px;
}
.p-datepicker table td > span {
  width: 1.5rem;
  height: 1.5rem;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #e5e7eb;
  padding: 0;
}
.p-datepicker .p-timepicker button {
  width: 1rem;
  height: 1rem;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 0;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  font-size: 0.7rem;
  padding: 8px;
  margin-top: 4px;
}
.p-datepicker-header {
  padding: 0px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  font-size: 12px;
  padding: 0;
}
.group-by-widget thead .p-checkbox {
  display: none;
}
.object-fit-contain {
  object-fit: contain;
}
